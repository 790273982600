.d-header-cuotas-y-pago-all {
  text-align: center;
  grid-area: header;
  display: grid;
  grid-template-columns: 100px 50px 120px 90px 120px 90px 120px 60px 70px 100px 1fr;
  min-height: 2.5em;
  background: var(--primary-platform) !important;
  justify-content: center;
  align-items: center;
  color:white;
}

.d-grid-cuotas-y-pago {
  display: grid;
  grid-template-areas:  "title title"
                        "header body";
}

.d-grid-cuotas-y-pago {
  display: grid;
  grid-template-areas: "body";
}

.section-cuota-y-pago .d-grid-body {
  text-align: center;
  grid-area: body;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 100px 50px 120px 90px 120px 90px 120px 60px 70px 100px 1fr;
}

.section-cuota-y-pago .d-grid-title {
  grid-area: title;
  background: var(--primary-platform) !important;
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 50px;
  padding: .5em 0;
  text-align: center;
}

.section-cuota-y-pago .d-grid-title > *, .section-cuota-y-pago .d-grid-header > *, .section-cuota-y-pago .d-grid-body > * {
  min-height: 2em;
}

@media screen and (max-width: 920px) {
  .d-grid-cuotas-y-pago {
    display: grid;
    grid-template-areas:  "title title"
                          "header body";         
  }

  .d-header-cuotas-y-pago-all {
    display: none !important;
  }

  .d-grid-header div:nth-of-type(odd), .d-grid-body div:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
  }
}

.section-cuota-y-pago > *:nth-of-type(odd) { 
  background-color: rgba(0,0,0,.05);
}

@media screen and (min-width: 920px) {
  .section-cuota-y-pago {
    min-width: 1200px;
  }
}