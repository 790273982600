@font-face {
	font-family: 'Museo';
	src: url('../fonts/Museo-Regular-300.ttf');
}

.a-link {
  color: var(--primary-platform) !important;
  text-decoration: none !important;
}

.a-link:hover {
  text-decoration: underline solid 1px var(--primary-platform) !important;
}

html, body, #root {
  min-height: 100% !important;
  font-family: "Museo";
}

.cursor-pointer {
  cursor: pointer;
}

.bg-login {
  background-color: var(--color-dark-platform);
  min-height: 100vh;
}

.bg-login-image {
  background-size: cover;
    background-repeat: no-repeat;
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
}

.h1-login-title{
    color: white;
    text-decoration: none;
    font-size: 3em;
    font-weight: 800;
    letter-spacing: -3px;
    line-height: 1;
    text-shadow: grey 3px 2px 0;
    position: relative;
}

.btn-platform {
  background-color: var(--primary-platform) !important;
  color: var(--color-text-base) !important;
  margin: 0em .25em;
}

.btn-platform-out{
  color: var(--primary-platform) !important;
  border: solid 1px;
  border-color: var(--primary-platform) !important;
  margin: 0em .25em;
}

.th-platform {
  text-align: center;
  background-color: var(--primary-platform) !important;
  color: var(--color-text-base) !important;
}

.th-platform, .td-patform {
  padding: .5em;
}

.container-data {
  background-color: var(--color-bg-secondary);
    border-radius: 1em;
    width: 100%;
    height: auto;
    padding: 2em 1em 1em 1em;
}

@media screen and (max-width: 400px) {
  .container-data {
    padding: 2em .25em;
  }
  .container {
    padding: 0em;
  }
}


.logo-layout{
  height: 4.2em;
  width: auto;
  max-width: 14em;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 2.5em;
  margin-bottom: 3em;
}

.subtitle-dashboard {
  overflow-x: auto;
}

.subtitle-dashboard > a{
  font-weight: bold;
  padding: 0.5em 0.7em 1em 0.7em;
  display: inline-block;
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  text-decoration: none;
}

.subtitle-dashboard > a:not(.active) {
  color: var(--color-text-base);
  background-color: var(--primary-platform);
}

.subtitle-dashboard > a:hover{
  text-decoration: underline;
}

.subtitle-dashboard > a.active {
  background-color: var(--color-bg-secondary) !important;
  color: var(--primary-platform) !important;
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  box-shadow: 0 -0.2em 5px -5px #333;
}

.color-platform {
  color: var(--primary-platform);
}

.nav-sidebar {
  background: var(--primary-platform);
    margin: 1em 0 0 1em;
    padding: 1em 0 0 0;
    border-radius: 2em;
    width: 18em;
    position: absolute;
    top: 0;
    left: 0;
    min-height: calc(100% - 2em);
    z-index: 999;
    color: var(--color-text-base);
    transition: all 0.3s;
}

.nav-sidebar > ul{
  padding: 2em 0 2em 1em;
}

.nav-sidebar ul li a, .nav-sidebar ul li span {
  color: var(--color-text-base);
  padding: 10px;
  font-size: 1.1em;
  display: block;
  text-decoration: none;
}

.container-dashboard{
  margin-left: 22em;
  margin-right: 3em;
  padding-top: 6em;
}

.nav-sidebar ul li.active > a {
  border-radius: 1em 0em 0em 1em;
  color: var(--primary-platform);
  background: var(--color-text-base);
}


.active-top:before {
  content: "";
  display: block;
  z-index: 4;
  position: absolute;
  top: -2em;
  right: 0;
  height: 2em;
  width: 2em;
  background-color: var(--primary-platform);
  border-bottom-right-radius: 1em;
}

.active-top:after {
  content: "";
  display: block;
  background-color: white;
  position: absolute;
  top: -2em;
  right: 0;
  height: 2em;
  width: 2em;
  z-index: 2;
}

.active-down, .active-top {
  position: relative;
}

.active-down:before {
  content: "";
  display: block;
  background-color: var(--primary-platform);
  border-top-right-radius: 1em;
  position: absolute;
  height: 2em;
  width: 2em;
  bottom: -2em;
  right: 0;
  z-index: 4;
}

.active-down:after {
  content: "";
  display: block;
  background-color: white;
  position: absolute;
  height: 2em;
  width: 2em;
  bottom: -2em;
  right: 0;
  z-index: 2;
}

.spinner {
  animation: spin infinite 2s linear;

  /*You can increase or decrease the timer (5s) to 
   increase or decrease the speed of the spinner*/
}

@keyframes spin {
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
}


/* ARRANCAN LOS STEPS */
.text-step {
  color: var(--primary-platform);
}

.multi-steps > li.is-active:before,
.multi-steps > li.is-active ~ li:before {
  content: counter(stepNum);
  font-family: inherit;
  font-weight: 700;
}

.multi-steps > li.is-active:after,
.multi-steps > li.is-active ~ li:after {
  background-color: var(--primary-platform);
}

.multi-steps {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.multi-steps > li {
  counter-increment: stepNum;
  text-align: center;
  display: table-cell;
  position: relative;
  font-weight: bold;
  color: green;
  z-index: 999;
}
.multi-steps > li:before {
  content: "\f00c";
  content: "\2713;";
  content: "\10003";
  content: "\10004";
  content: "\2713";
  display: block;
  margin: 0 auto 4px;
  background-color: green;
  width: 36px;
  height: 36px;
  line-height: 32px;
  text-align: center;
  font-weight: bold;
  color: white;
  border: 2px solid green;
  border-radius: 50%;
  z-index: 100;
}
.multi-steps > li:after {
  content: "";
  height: 2px;
  width: 100%;
  background-color: green;
  position: absolute;
  top: 16px;
  left: 50%;
  z-index: -1;
}
.multi-steps > li:last-child:after {
  display: none;
}
.multi-steps > li.is-active:before {
  background-color: green;
  border-color: green;
}
.multi-steps > li.is-active ~ li {
  color: var(--primary-platform);
}
.multi-steps > li.is-active ~ li:before {
  background-color: var(--primary-platform);
  border-color: var(--primary-platform);
}

/* FINALIZAN LOS STEPS */


@media (min-width: 415px) and (max-width: 1199px){
  .container-dashboard {
    margin-left: 1em;
    margin-right: 1em;
    padding-top: 3em;
  }
}

@media only screen and (max-width: 714px){
  .container-dashboard {
    margin-left: .5em;
    margin-right: .5em;
    padding-top: 1em;
  }
}

@media only screen and (max-width: 414px) {
  #nav-mobile-header{
    background-color: var(--primary-platform) !important;
    max-height: auto;
  }

  .navbar-toggler{
    color: var(--color-text-base) !important;
    border-color: var(--color-text-base) !important;
    font-size: 1.5em;
  }

  .navbar-brand{
    margin-right: 0 !important;
    max-height: 3em;
    position: relative;
  }

  #navbarSupportedContent{
    color: var(--color-text-base);
    text-align: center  !important;
    padding-top: 1em;
  }

  #navbarSupportedContent ul li{
    text-align: left;
    padding: .3em .3em;
    text-align: center;
  }

  #navbarSupportedContent ul li.sub-menu{
    text-align: left;
    padding: .3em 1.7em;
  }

  #navbarSupportedContent ul li a{
    color: var(--color-text-base);
  }

  .navbar-logo{
    height: 2.4em;
    width: 8em;
    z-index: 999;
    margin-top: 0;
  }
}

/* Ipads y tablets */
@media (min-width: 415px) and (max-width: 1200px) {
  #nav-mobile-header{
    background-color: var(--primary-platform) !important;
    max-height: auto;
  }

  .navbar-toggler{
    color: var(--color-text-base) !important;
    border-color: var(--color-text-base) !important;
    font-size: 1.5em;
  }

  .sidebar-header {
    margin-top: 0em;
    margin-bottom: 0em;
  }

  .navbar-brand{
    margin-right: 0 !important;
    max-height: 2em;
    position: relative;
  }

  #navbarSupportedContent{
    color: var(--color-text-base);
    text-align: center  !important;
    padding-top: 1em;
  }

  #navbarSupportedContent ul li{
    padding: .3em .3em;
  }

  #navbarSupportedContent ul li a{
    color: var(--color-text-base);
  }

  .navbar-logo{
    height: 2.4em;
    width: 8em;
    z-index: 999;
    margin-top: -.45em;
  }
}

.navbar-logo {
  height: 4.2em;
  width: auto;
  max-width: 14em;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 415px) and (max-width: 1200px){
  .navbar-logo {
    height: 2.4em;
    width: 8em;
    z-index: 999;
    margin-top: -0.45em;
}
}


@media only screen and (max-width: 414px){
  .navbar-logo {
    height: 2.4em;
    width: 8em;
    z-index: 999;
    margin-top: 0;
}
}


@media (min-width: 1200px){
  .w-xl-50{
    width: 50%!important;
  }
}

@media (min-width: 992px){
  .w-lg-50{
    width: 50%!important;
  }
}

.bg-light-secondary{
  background-color: rgba(0,0,0,.05);
}

.container-recuperar-pwd {
  width: 50%;
}

@media (max-width: 1000px) {
  .container-recuperar-pwd {
    width: 90%;
  }
}

.bg-estadias-image{
  height: 200px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 2em;
}

.d-grid-solicitudes {
  display: grid;
  grid-template-areas: "body";
}

@media screen and (min-width: 921px) {
  .d-grid-solicitudes {
    display: grid;
    grid-template-areas: "body";        
  } 
  .d-grid-header, .d-grid-title{
    display: none !important;
  }
}


@media screen and (max-width: 920px) {
  .d-grid-solicitudes {
    display: grid;
    grid-template-areas:  "title title"
                          "header body";         
  }

  .d-grid-header , .d-grid-body {
    grid-template-columns: 1fr !important;
    grid-template-rows: repeat(8, 2em) !important;
    background: white !important;
    color: black !important;
    border-top: 2px solid black;
    margin-bottom: .5em;
    display: none !important;
  }

  .d-grid-header-all {
    display: none !important;
  }

  .d-grid-header div:nth-of-type(odd), .d-grid-body div:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
  }


.d-grid-body.show, .d-grid-header.show {
  display: grid !important;
}

}

/* .d-grid-header span , .d-grid-body span{
  padding: .5em;
} */

.d-grid-header-all ,.d-grid-header {
  text-align: center;
  grid-area: header;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  background: var(--primary-platform);
  justify-content: center;
  align-items: center;
  color:white;
}

.d-grid-body {
  text-align: center;
  grid-area: body;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}

.d-grid-title{
  grid-area: title;
  background: var(--primary-platform);
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2em;
  padding: .5em 0;
  text-align: center;
}

.d-grid-header {
  font-weight: 900;
}


.r-90 {
  transform: rotate(-90deg);
}

/* Grid */
.grid-container {
  text-align: center;
}

.grid-container > .th-platform {
  display: grid;
  text-align: center;
}

.grid-container > .th-platform, .grid-container > .td-platform > .grid-row   {
  display: grid;
  grid-gap: .5em;
}

.grid-container > .td-platform > .grid-row:nth-child(odd)  {
  background-color: rgba(0,0,0,.05);
}

.grid-container > .td-platform > .grid-row  {
  padding: .5em;
}

.th-title {
  display: none;
}

@media screen and (min-width: 990px) {
  .td-body-odd {
    background-color: var(--primary-platform) !important;
    color: var(--color-text-base) !important;
  }
}

@media screen and (max-width: 990px) {
  .grid-container > .td-platform > .grid-row  {
    padding: 0;
  }

  .grid-container > .td-platform > .grid-row > span {
    padding: .5em;
  }

  .grid-container > .td-platform > .grid-row > span:nth-child(odd) {
    background-color: rgba(0,0,0,.05);
  }

  .th-title {
    display:  block;
    text-align: center;
    background-color: var(--primary-platform) !important;
    color: var(--color-text-base) !important;
    padding: .5em;
  }
  .grid-container > .th-platform {
    display: none;
  }
  .grid-container > .td-platform > .grid-row {
    grid-template-columns: 1fr !important;
    text-align: left;
  }
  .grid-container > .td-platform > .grid-row > *::before {
    text-align: left;
    position: relative;
    left: 0;
    content: attr(title)': ';
    font-weight: 900;
  }
}
